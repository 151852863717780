import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";
import Headline from "../components/Headline";

export const query = graphql`
{
  allSanityDatenschutz {
  edges{
    node{
        _rawDatenschutz
    }
    
  }
  }
  allSanityAgbs {
  edges{
    node{
        agbfile {
          asset {
            url
          }
        }
    }
  }
  }
  }
 `

const Datenschutz = ({data}) => {
  return (
    <Layout agbs={data.allSanityAgbs.edges[0].node.agbfile.asset.url}>
        <SEO title={"Datenschutz | Matthey & Melchior"}/>

        <div className="basepage">
            <div className={"headline__container"} >
                <h1 className={"center minion"}>Datenschutz</h1>
                <div className="separator title"></div>
            </div>
            <div className="text">
                <MyPortableText value={data.allSanityDatenschutz.edges[0].node._rawDatenschutz}></MyPortableText>
            </div>
        </div>
    </Layout>
  )
}

export default Datenschutz
